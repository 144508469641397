const definition = {
  prefix: "fac",
  iconName: "sidebar-flip",
  icon: [
    20,
    20,
    [],
    null,
    [
      "M8.89062 6.53125C8.89062 6.25511 9.11448 6.03125 9.39062 6.03125H11.3906C11.6668 6.03125 11.8906 6.25511 11.8906 6.53125C11.8906 6.80739 11.6668 7.03125 11.3906 7.03125H9.39062C9.11448 7.03125 8.89062 6.80739 8.89062 6.53125Z",
      "M9.39062 8.03125C9.11448 8.03125 8.89062 8.25511 8.89062 8.53125C8.89062 8.80739 9.11448 9.03125 9.39062 9.03125H11.3906C11.6668 9.03125 11.8906 8.80739 11.8906 8.53125C11.8906 8.25511 11.6668 8.03125 11.3906 8.03125H9.39062Z",
      "M2 12.5H12C13.1046 12.5 14 11.6046 14 10.5V3.5C14 2.39543 13.1046 1.5 12 1.5H2C0.895431 1.5 0 2.39543 0 3.5V10.5C0 11.6046 0.895431 12.5 2 12.5ZM12 2.8H8.20624V11.2H12C12.3866 11.2 12.7 10.8866 12.7 10.5V3.5C12.7 3.1134 12.3866 2.8 12 2.8ZM2 2.8H6.90624L6.90624 11.2H2C1.6134 11.2 1.3 10.8866 1.3 10.5V3.5C1.3 3.1134 1.6134 2.8 2 2.8Z",
    ],
  ],
}

export { definition as facSidebarFlip }
