/* FontAwesome SVG + JS subsetting to reduce load

   Make sure you add the icon to the appropriate import list AND
   to the library list

*/

import { dom, library, config } from "@fortawesome/fontawesome-svg-core"

import {
  faClone as faCloneLight,
  faDownload as faDownloadLight,
  faEllipsisH,
  faStar as faStarLight,
  faUserPlus as faUserPlusLight,
} from "@fortawesome/pro-light-svg-icons"

import {
  faCheckCircle as faCheckCircleDuo,
  faMinusCircle as faMinusCircleDuo,
  faInfoCircle as faInfoCircleDuo,
  faExclamationTriangle as faExclamationTriangleDuo,
  faTimesCircle as faTimesDuo,
} from "@fortawesome/pro-duotone-svg-icons"

import {
  faAnalytics as faAnalytics2,
  faAngleLeft,
  faArchive,
  faArrowAltRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faChartBar,
  faChartPie,
  faChairOffice,
  faCheck,
  faCheckCircle as faCheckCircleRegular,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardCheck,
  faClock,
  faClone,
  faCodeMerge,
  faCog,
  faComment,
  faCommentAltLines,
  faComments,
  faCompass,
  faCompressAlt,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH as faEllipsisHRegular,
  faEllipsisV,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExchangeAlt,
  faExclamationCircle as faExclamationCircleRegular,
  faExclamationTriangle as faExclamationTriangleRegular,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFillDrip,
  faFilter,
  faFlag,
  faGripVertical,
  faHeart,
  faHistory,
  faHome,
  faICursor,
  faInfoCircle as faInfoCircleRegular,
  faKey,
  faLayerGroup,
  faListUl,
  faLockAlt,
  faLockOpenAlt,
  faLongArrowAltRight,
  faMinus,
  faMoneyBill,
  faPaperPlane,
  faPaperclip,
  faPen,
  faPencil,
  faPencilRuler,
  faPercent,
  faPhone,
  faPlus,
  faPlusSquare,
  faPoundSign,
  faPrint,
  faRedoAlt,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSparkles,
  faStickyNote,
  faStopwatch,
  faSyncAlt,
  faTimes,
  faTrashAlt,
  faUndo,
  faUndoAlt,
  faUpload,
  faUsdSquare,
  faUser,
  faUserPlus,
  faUsers,
  faYenSign,
  faHashtag,
} from "@fortawesome/pro-regular-svg-icons"

import {
  faAnalytics,
  faArrowAltRight as faArrowAltRightSolid,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck as fasCheck,
  faCheckCircle,
  faComment as faCommentSolid,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEye as faEyeSolid,
  faGripVertical as fasGripVertical,
  faInfoCircle,
  faLink,
  faLowVision,
  faMinusCircle,
  faPhone as faPhoneSolid,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTerminal,
  faTimes as faTimesSolid,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons"

import {
  faBehance,
  faBloggerB,
  faDribbble,
  faDropbox,
  faFacebookF,
  faGithub,
  faHubspot,
  faInstagram,
  faLinkedinIn,
  faMediumM,
  faPinterest,
  faSnapchatGhost,
  faTwitter,
  faVimeoV,
  faWordpressSimple,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

// Custom icons
import {
  facBrazilianRealSign,
  facChartSections,
  facChfSign,
  facCodeFork,
  facDanishKroneSign,
  facFolderTree,
  facHongKongDollarSign,
  facIndianRupeeSign,
  facKrSign,
  facLadder,
  facListOl,
  facPhilippinePesoSign,
  facRectangleHistoryCirclePlus,
  facRomanianLeuSign,
  facShortText,
  facSidebarFlip,
  facSingaporeDollarSign,
  facSouthAfricanRandSign,
  facSquareBrazilianRealSign,
  facSquareChfSign,
  facSquareDanishKroneSign,
  facSquareDollarSign,
  facSquareEuroSign,
  facSquareHongKongDollarSign,
  facSquareIndianRupeeSign,
  facSquareKrSign,
  facSquarePhilippinePesoSign,
  facSquarePoundSign,
  facSquareRomanianLeuSign,
  facSquareSingaporeDollarSign,
  facSquareSouthAfricanRandSign,
  facSquareThaiBahtSign,
  facSquareTurkishLiraSign,
  facSquareUaeDirhamSign,
  facSquareUaeDirhamTextSign,
  facSquareYenSign,
  facThaiBahtSign,
  facTurkishLiraSign,
  facUaeDirhamSign,
  facUaeDirhamTextSign,
} from "icons"

// This icon comes from FontAwesome 6.4.2
// We should update the code to pull from the library once updated
const facArrowUpRightFromSquare = {
  prefix: "fac",
  iconName: "arrow-up-right",
  icon: [
    20,
    20,
    [],
    null,
    "M11.4909 3.57873C11.4909 3.99198 11.8234 4.32445 12.2367 4.32445H15.4091L8.47699 " +
      "11.2534C8.18492 11.5455 8.18492 12.0178 8.47699 12.3067C8.76906 12.5957 9.24135 " +
      "12.5988 9.53032 12.3067L16.4593 5.37777V8.55019C16.4593 8.96344 16.7918 9.2959 " +
      "17.205 9.2959C17.6183 9.2959 17.9507 8.96344 17.9507 8.55019V3.57873C17.9507 3.16547 " +
      "17.6183 2.83301 17.205 2.83301H12.2367C11.8234 2.83301 11.4909 3.16547 11.4909 " +
      "3.57873ZM4.28232 3.8273C3.04567 3.8273 2.04517 4.82781 2.04517 6.06446V16.5045C2.04517 " +
      "17.7412 3.04567 18.7417 4.28232 18.7417H14.7224C15.959 18.7417 16.9595 17.7412 16.9595 " +
      "16.5045V12.5274C16.9595 12.1141 16.6271 11.7816 16.2138 11.7816C15.8006 11.7816 15.4681 " +
      "12.1141 15.4681 12.5274V16.5045C15.4681 16.9178 15.1356 17.2502 14.7224 17.2502H4.28232C3.86907 " +
      "17.2502 3.5366 16.9178 3.5366 16.5045V6.06446C3.5366 5.6512 3.86907 5.31874 4.28232 " +
      "5.31874H8.25949C8.67274 5.31874 9.00521 4.98627 9.00521 4.57302C9.00521 4.15977 8.67274 " +
      "3.8273 8.25949 3.8273H4.28232Z",
  ],
}

// Disable autoAddCSS so we can avoid using style-src: unsafe-inline in
// our Content-Security-Policy header.
config.autoAddCss = false

library.add(
  faAnalytics,
  faAnalytics2,
  faAngleLeft,
  faArchive,
  faArrowAltRight,
  faArrowAltRightSolid,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBehance,
  faBloggerB,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  facArrowUpRightFromSquare,
  faChartBar,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckCircleDuo,
  faCheckCircleRegular,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChairOffice,
  faClipboardCheck,
  faClock,
  faClone,
  faCloneLight,
  faCodeMerge,
  faCog,
  faComment,
  faCommentAltLines,
  faCommentSolid,
  faComments,
  faCompass,
  faCompressAlt,
  faDesktop,
  faDollarSign,
  faDownload,
  faDownloadLight,
  faDribbble,
  faDropbox,
  faEdit,
  faEllipsisH,
  faEllipsisHRegular,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faEuroSign,
  faExchange,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationCircleRegular,
  faExclamationTriangle,
  faExclamationTriangleDuo,
  faExclamationTriangleRegular,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faEyeSolid,
  faFacebookF,
  faFile,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFillDrip,
  faFilter,
  faFlag,
  faGithub,
  faGripVertical,
  faHeart,
  faHistory,
  faHome,
  faHubspot,
  faICursor,
  faInfoCircle,
  faInfoCircleDuo,
  faInfoCircleRegular,
  faInstagram,
  faKey,
  faLayerGroup,
  faLink,
  faLinkedinIn,
  faListUl,
  faLockAlt,
  faLockOpenAlt,
  faLongArrowAltRight,
  faLowVision,
  faMediumM,
  faMinus,
  faMinusCircle,
  faMinusCircleDuo,
  faMoneyBill,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencil,
  faPencilRuler,
  faPercent,
  faPhone,
  faPhoneSolid,
  faPinterest,
  faPlus,
  faPlusSquare,
  faPoundSign,
  faPrint,
  faRedoAlt,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSnapchatGhost,
  faSort,
  faSortDown,
  faSortUp,
  faSparkles,
  faStar,
  faStarLight,
  faStickyNote,
  faStopwatch,
  faSyncAlt,
  faTerminal,
  faTimes,
  faTimesDuo,
  faTimesCircle,
  faTimesSolid,
  faTrashAlt,
  faTwitter,
  faUndo,
  faUndoAlt,
  faUpload,
  faUsdSquare,
  faUser,
  faUserPlus,
  faUserPlusLight,
  faUsers,
  faVimeoV,
  faWordpressSimple,
  faYenSign,
  faYoutube,
  facBrazilianRealSign,
  facCodeFork,
  facChartSections,
  facChfSign,
  facDanishKroneSign,
  facFolderTree,
  facHongKongDollarSign,
  facIndianRupeeSign,
  facKrSign,
  facLadder,
  facListOl,
  facRectangleHistoryCirclePlus,
  facRomanianLeuSign,
  facSidebarFlip,
  facSingaporeDollarSign,
  facSquareBrazilianRealSign,
  facSquareChfSign,
  facSquareDanishKroneSign,
  facSquareDollarSign,
  facSquareEuroSign,
  facSquareHongKongDollarSign,
  facSquareIndianRupeeSign,
  facSquareKrSign,
  facSquarePoundSign,
  facSquareRomanianLeuSign,
  facSquareSingaporeDollarSign,
  facSquareYenSign,
  fasCheck,
  fasGripVertical,
  faHashtag,
  facSouthAfricanRandSign,
  facSquareSouthAfricanRandSign,
  facPhilippinePesoSign,
  facSquarePhilippinePesoSign,
  facThaiBahtSign,
  facSquareThaiBahtSign,
  facTurkishLiraSign,
  facSquareTurkishLiraSign,
  facUaeDirhamSign,
  facUaeDirhamTextSign,
  facSquareUaeDirhamSign,
  facSquareUaeDirhamTextSign,
  facShortText,
)

const FontAwesomeStub = {
  config,
  dom,
  library,
  mount: () => {
    const $ = window.jQuery
    $(() => dom.watch())
  },
}

export { FontAwesomeStub, facChartSections }
